import BaseApi from "./BaseApi";

export default class Work extends BaseApi{
    construct() {}

    static async search(params) {
        return await Work.getAxiosInstance().get(Work.getApiUrl(),{params: params});
    }

    static async uploadCover(params, id){
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'upload-cover/' + id,params);
    }

    static async removeCover(id){
        return await Work.getAxiosInstance().delete(Work.getApiUrl() + 'remove-cover/' + id);        
    }

    static async loadImages(params, id){
        //\Route::name("admin.works.load-images")->post("load-images/{id}", "Photos\GetImages");
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'load-images/' + id,params);
    }

    static async getSingleImage(params, id){
        //\Route::name("admin.works.load-images")->post("load-images/{id}", "Photos\GetImages");
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'get-image/' + id,params);
    }

    static async create(params) {
        return await Work.getAxiosInstance().post(Work.getApiUrl(),params);
    }

    static async setWorkers(params, id) {
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'set-workers/' + id,params);
    }

    static async setMachines(params, id) {
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'set-machines/' + id,params);
    }

    static async setLocal(params) {
        return await Work.getAxiosInstance().post(Work.getApiUrl() + 'setLocal',params);
    }

    static async update(params, id) {
        return await Work.getAxiosInstance().put(Work.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Work.getAxiosInstance().delete(Work.getApiUrl() + id);
    }

    static async find(id) {
        return await Work.getAxiosInstance().get(Work.getApiUrl() + id);
    }

    static async getLocations(params) {
        let url = Work.getApiUrl() + "locations";

        return Work.getAxiosInstance().get(url,{params: params});
    }

    static async list(params) {
        let url = Work.getApiUrl() + "list";

        return Work.getAxiosInstance().get(url,{params: params});
    }

    static async listStatus() {
        let url = Work.getApiUrl() + "status";

        return Work.getAxiosInstance().get(url);
    }

    static async start(id) {
        let url = Work.getApiUrl() + id + "/start";

        return Work.getAxiosInstance().post(url);
    }

    static async finish(id) {
        let url = Work.getApiUrl() + id + "/finish";

        return Work.getAxiosInstance().post(url);
    }

    static async exportEquipments(id, params) {
        let url = Work.getApiUrl() + id + "/equipments/export";

        return Work.getAxiosInstance().post(url, params);
    }

    static async searchSubcontractors(params){
        return await Work.getAxiosInstance().get(Work.getApiUrl() + 'subcontractors/get-subcontractors',{params: params});
    }

    static async removeSubcontractors(id){
        return await Work.getAxiosInstance().delete(Work.getApiUrl() + 'subcontractors/remove-subcontractors/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/works-settings/works/";
    }
}