<template>
    <v-container fluid style="height: 100%;" class="ma-0 pa-0">
        <v-card elevation="0" outlined height="255px" style="background-color: #e0e0e0;">
            
                <v-img
                    v-if="img_url"
                    :src="img_url"
                    contain
                    height="100%"
                    class="full-size"
                >
                    <template v-slot:default>
                        <div
                            class="overlay"
                            v-if="img_url"
                        >
                            <v-btn
                                color="primary"
                                @click="triggerFileInput"
                                fab
                                small
                                class="overlay-btn"
                            >
                                <v-icon color="white">mdi-camera</v-icon>
                            </v-btn>
                            <v-btn
                                color="error"
                                @click="removeImage"
                                fab
                                small
                                class="overlay-btn ml-2"
                            >
                                <v-icon color="white">mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-img>
                <v-row v-else justify="center" align="center" class="h-100">
                    <v-col cols="12" justify="center" align="center">
                        <v-file-input
                            v-model="fields.logo"
                            :rules="rules"
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                            @change="onFileChange"
                            class="image-upload circle-btn"
                            dark
                        ></v-file-input>
                    </v-col>
                </v-row>
        </v-card>
        <SuccessSnackBar v-model="success.show" :message="success.message" />
        <input
            type="file"
            ref="fileInput"
            style="display: none;"
            @change="handleOverlayFileInput"
            accept="image/png, image/jpeg, image/bmp"
        />
        <DialogConfirmation v-if="remove"
            title="Remover foto Cover" type="info"
            :opened="remove" @on-submit="removePhoto" @on-cancel="remove = false">
            Tem a certeza que deseja remover a foto cover da obra?
        </DialogConfirmation>

    </v-container>
</template>

<script>
import Work from '@/api/Work.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default {
    name: "Photos",
    components: {
        SuccessSnackBar,
        DialogConfirmation
    },
    props: ['work_object'],
    data() {
        return {
            remove: false,
            success: {
                show: false,
                message: ''
            },
            loading: true,
            fields: {
                logo: null
            },
            error: {
                show: false,
                message: '',
                title: null
            },
            logo_changed: false,
            img_url: null,
            rules: [
                value => !value || value.size < 2000000 || 'Image to big',
            ]
        };
    },
    mounted() {
        this.fillBaseData()
    },
    methods: {
        fillBaseData() {
            this.loading = false;

            if (this.work_object.cover != null) {
                this.img_url = this.work_object.cover;
            }
        },
        onFileChange(file) {
            if (!file) return;

            if (file.size >= 2000000) {
                this.fields.logo = null;
                this.error.message = 'Image bigger than 2MB';
                this.error.show = true;
                return;
            }

            this.logo_changed = true;

            const reader = new FileReader();
            reader.onload = (e) => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);

            this.submit()
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleOverlayFileInput(event) {
            const file = event.target.files[0];
            this.onFileChange(file);
            this.fields.logo = file; // Mantém o estado atualizado
            this.submit()
        },
        async submit() {
            const formData = new FormData();
            if (this.fields.logo) formData.append('logo', this.fields.logo);
            else return;

            await Work.uploadCover(formData, this.$route.params.id)
                .then((response) => {
                    this.fields.logo = null;
                    this.img_url = response.data.coverUrl;

                    this.success.message = 'Foto cover inserida com sucesso'
                    this.success.show = true;

                    this.loading = false;

                    this.$emit('update-object');
                })
                .catch((err) => {
                    this.loading = false;

                    if (err.response.status == 422) {
                        this.$refs.form.setErrors(err.response.data.errors);
                        return;
                    }

                    this.error.title = 'ERROR: ' + ' ' + err.response.status;
                    this.error.message = err.response.data.message;
                });
        },
        removeImage() {
            this.remove = true
        },
        removePhoto(){
            Work.removeCover(this.$route.params.id).then(()   =>  {                
                this.fields.logo = null;
                this.img_url = null;
                this.remove = false
                this.success.message = 'Foto cover removida com sucesso'
                this.success.show = true;
            })
        }
    },
}
</script>

<style>
.full-size {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.overlay-btn {
    background: rgba(0, 0, 0, 0.6) !important;
    color: white !important;
    box-shadow: none;
}

.image-upload .v-input__control {
    display: none !important;
}

.circle-btn {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    text-align: center;
}

.v-input__prepend-outer {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -5px;
}
</style>
